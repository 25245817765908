.ledactive {
    border: 1px solid green;
}

.lederror {
    border: 1px solid red;
}

.ledborder {
    border: 1px solid transparent;
}