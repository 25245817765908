
.wcontrolbackground {
    background: #fff;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
/*    border: 0px solid rgba(0,0,0,0.2); */
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
}
