/*
.App {
  background-color: #efefef !important;
}
*/

#AppMain {
  margin-top: 0px;
  width: auto;
  background-color: rgba(204, 204, 204, 0.25);
}

a.rootlink  {
  color: #ffffff;
}


a.rootlink:visited {
  color: #ffffff;
}

a.rootlink:hover {
  color: #ffffff;
}

.leaflet-container {
  height: 600px;
  width: 100%;
}
