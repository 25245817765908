.axis text {
    fill: rgb(128, 128, 128);
}

.axis path {
    stroke: rgb(211, 211, 211);
    stroke-dasharray: 2, 2;
}

.axis .baseline {
    stroke: rgb(211, 211, 211);
}

.tooltip-content {
    border: 1px solid black;
    background: white;
    padding: 20px;
    position: relative;
    transform: translate(calc(-50% + 7px), calc(-100% - 20px));
}

.tooltip-content:before {
    background: inherit;
    content: "";
    padding: 0px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 99;
    border-right: inherit;
    border-bottom: inherit;
    bottom: -8px;
    left: calc(50% - 15px);
}

.divider-spacing {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
